<template>
        <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-10 col-md-3 col-lg-3 add-edit">
                <form @submit.prevent="onUpdateForm">
                    <h3>Update a Score</h3>
                    <div class="d-grid gap-1">
                        <div class="form-group">
                            <label>Car Number</label>
                            <input type="number" v-model="score.carNumber" v-validate="'required|numeric'" id="carNumber" name="carNumber" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && errors.has('carNumber') }" />
                            <div v-if="submitted && errors.has('carNumber')" class="invalid-feedback">{{ errors.first('carNumber') }}</div> 
                        </div>

                        <div class="input-group">
                            <div class="form-group year">
                                <label>Year</label>
                                <select v-model="score.year" id="year" class="form-select form-control form-control-sm">
                                    <option>Select</option>
                                    <option v-for="year in filteredYears" v-bind:key="year.Year">
                                        {{year.Year}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group category">
                                <label>Category</label>
                                <select v-model="score.category" id="category" class="form-select form-control form-control-sm">
                                    <option>Select</option>
                                    <option v-for="cats in filteredCats" v-bind:key="cats.CategoryName">
                                        {{cats.CategoryName}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="input-group">
                            <div class="form-group make">
                                <label>Make</label>
                                <select v-model="score.make" id="make" @change="getModels($event)" class="form-select form-control form-control-sm">
                                    <option>Select</option>
                                    <option v-for="make in filteredMakes" v-bind:key="make.VehicleMake">
                                        {{make.VehicleMake}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group model">
                                <label>Model</label>
                                <select v-model="score.model" id="model" class="form-select form-control form-control-sm">
                                    <option>Select</option>
                                    <option v-for="model in filteredModels" v-bind:key="model.Model">
                                        {{model.Model}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Exterior/Body</label>
                            <input type="number" v-model="score.exteriorBody" v-validate="'required|numeric|max_value:20'" id="exteriorBody" name="exteriorBody" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && errors.has('exteriorBody') }" />
                            <div v-if="submitted && errors.has('exteriorBody')" class="invalid-feedback">{{ errors.first('exteriorBody') }}</div> 
                        </div>

                        <div class="form-group">
                            <label>Interior</label>
                            <input type="number" v-model="score.interior" v-validate="'required|numeric|max_value:20'" id="interior" name="interior" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && errors.has('interior') }" />
                            <div v-if="submitted && errors.has('interior')" class="invalid-feedback">{{ errors.first('interior') }}</div> 
                        </div>

                        <div class="form-group">
                            <label>Under Hood/Engine</label>
                            <input type="number" v-model="score.underhood" v-validate="'required|numeric|max_value:20'" id="underhood" name="underhood" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && errors.has('underhood') }" />
                            <div v-if="submitted && errors.has('underhood')" class="invalid-feedback">{{ errors.first('underhood') }}</div> 
                        </div>

                        <div class="form-group">
                            <label>Wheels</label>
                            <input type="number" v-model="score.wheels" v-validate="'required|numeric|max_value:20'" id="wheels" name="wheels" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && errors.has('wheels') }" />
                            <div v-if="submitted && errors.has('wheels')" class="invalid-feedback">{{ errors.first('wheels') }}</div> 
                        </div>

                        <div class="form-group">
                            <label>Trunk</label>
                            <input type="number" v-model="score.trunk" v-validate="'required|numeric|max_value:20'" id="trunk" name="trunk" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && errors.has('trunk') }" />
                            <div v-if="submitted && errors.has('trunk')" class="invalid-feedback">{{ errors.first('trunk') }}</div> 
                        </div>

                        <button class="btn btn-primary">Update</button>
                        <button class="btn btn-secondary" @click.prevent="onCancel" >Exit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { db } from '@/services/firebase/index.js';
        import _ from 'lodash';
        export default {
        data() {
                return {
                score: {
                },
                categories: [],
                years: [],
                makes: [],
                models: [],
                submitted: false,
                dict: {
                    custom: {
                        carNumber: {
                            decimal: "A Car Number is Required."
                        },
                        exteriorBody: {
                            decimal: "A Exterior score is Required."
                        },
                        interior: {
                            decimal: "A Interior score is Required."
                        },
                        underhood: {
                            decimal: "A Under Hood score is Required."
                        },
                        wheels: {
                            decimal: "A Wheels score is Required."
                        },
                        trunk: {
                            decimal: "A Trunk score is Required."
                        },

                    }
                }
            }
        },
        mounted() {
            this.$validator.localize('en', this.dict);
        },
        created() {
            var vm = this;
            let dbRef = db.collection(this.scoresDbName).doc(this.$route.params.id);
            dbRef.get().then((doc) => {
                vm.score = doc.data();
                vm.getModels(vm.score.make);
            }).catch((error) => {
                console.log(error)
            });
            this.getCategories();
            this.getYears();
            this.getMakes();
        },
        computed: {
            filteredCats() {
                return _.orderBy(this.categories, 'CategoryName', 'asc');
            },
            filteredYears() {
                return _.orderBy(this.years, 'Year', 'asc');
            },
            filteredMakes() {
                return _.orderBy(this.makes, 'VehicleMake', 'asc');
            },
            filteredModels() {
                return _.orderBy(this.models, 'Model', 'asc');
            },
            scoresDbName() {
                return this.$store.getters.getScoresDatabaseName;
            },
            categoryDbName() {
                return this.$store.getters.getCategoryDatabaseName;
            }
        },
        methods: {
            onUpdateForm(event) {
                event.preventDefault()
                const vm = this;
                this.submitted = true;
                this.$validator
                    .validateAll()
                    .then(function(success) {
                        if (!success) return;
                        vm.submitted = false;
                        db.collection('scores').doc(vm.$route.params.id)
                        .update(vm.score).then(() => {
                            alert("Score successfully updated!");
                            vm.$router.push({ name: 'Score' })
                        }).catch((error) => {
                            console.log(error);
                        });
                     })
                    .catch(function() {
                        alert('Error Validating Score Card Data');
                    })
            },
            onCancel() {
                this.show = false;
                this.$router.push({ name: 'Score' });
            },
            getCategories() {
                db.collection(this.categoryDbName).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.categories.push(doc.data());
                    });
                })
                .catch((error) => {
                    console.log('Error getting categories: ', error);
                });
            },
            getYears() {
                db.collection("years").get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.years.push(doc.data());
                    });
                })
                .catch((error) => {
                    console.log("Error getting years: ", error);
                });
            },
            getMakes() {
                db.collection("vehicle_make").get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.makes.push(doc.data());
                    });
                })
                .catch((error) => {
                    console.log("Error getting makes: ", error);
                });
            },
            getModels(make) {
                this.models = [];
                db.collection('vehicle_models').where('VehicleMake', '==', make).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.models = doc.data().Models;
                    });
                })
                .catch((error) => {
                    console.log("Error getting makes: ", error);
                });
            }
        }
    }
</script>